import { deepFreeze } from './utils/utils';
export const TOOLTIP_DELAY = 200;
const InQStyleGuide = deepFreeze({
    black: '#000000',
    green: '#83D017',
    grey: {
        dark: '#1E1E1E',
        medium: '#2C2C2C',
    },
    red: '#ff2d2d',
    white: '#ffffff',
});
export default InQStyleGuide;
