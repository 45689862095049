var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DyteButton, DyteCameraToggle, DyteControlbarButton, DyteDialog, DyteDialogManager, DyteGrid, DyteMicToggle, DyteNotifications, DyteParticipantCount, DyteParticipants, DyteParticipantsAudio, DyteScreenShareToggle, DyteTooltip, defaultConfig, } from '@dytesdk/react-ui-kit';
import { DyteProvider, useDyteMeeting, useDyteSelector, } from '@dytesdk/react-web-core';
import DyteVideoBackgroundTransformer from '@dytesdk/video-background-transformer';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { raam } from 'raam-client-lib';
import InQStyleGuide, { TOOLTIP_DELAY } from '../../constants';
import { blurSvgString, cameraSwitchSvgString, captionSvgString, chatSvgString, chatWithDotSvgString, endCallSvgString, } from '../../iconStrings';
import { applyColor, isBackCamera, isFrontCamera } from '../../utils/utils';
import { DeviceOrientationProvider, useDeviceOrientation, } from '../DeviceOrientationProvider';
import SettingsButton from '../Settings/SettingsButton';
import SettingsDialog from '../Settings/SettingsDialog';
import useIconPack from '../../hooks/useIconPack';
import useLocale from '../../hooks/useLocale';
import SetupScreen from '../SetupScreen';
const useStyles = makeStyles((theme) => ({
    meetingCallContainer: {
        backgroundColor: InQStyleGuide.grey.medium,
        borderRadius: theme.spacing(0.75),
        color: 'white',
        display: 'flex',
        flex: 1,
        height: '100%',
        position: 'relative',
    },
    controlsContainer: {
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        position: 'absolute',
        pointerEvents: 'none',
        padding: theme.spacing(2),
        width: '100%',
        zIndex: 3,
    },
    bottomControlsContainer: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'space-between',
        pointerEvents: 'auto',
        width: '100%',
    },
    controlsRow: {
        display: 'flex',
        flexDirection: 'row',
        pointerEvents: 'auto',
    },
    portrait: {
        flexDirection: 'column',
    },
    controlItem: {
        marginLeft: theme.spacing(2),
    },
    portraitControlItem: {
        marginBottom: theme.spacing(2),
    },
    customButton: {
        backgroundColor: InQStyleGuide.black,
        borderRadius: theme.spacing(1),
        width: theme.spacing(7),
        '&:hover': {
            backgroundColor: InQStyleGuide.grey.dark,
        },
    },
    customControlButton: {
        height: 'auto',
        minWidth: theme.spacing(6.5),
    },
    videTilesContainer: {
        flex: 1,
        padding: `${theme.spacing(8)}px 0px`,
    },
    dyteParticipants: {
        borderRadius: theme.spacing(1),
        backgroundColor: InQStyleGuide.black,
        height: theme.spacing(40),
        maxWidth: theme.spacing(40),
        top: theme.spacing(9),
        paddingTop: theme.spacing(6),
    },
    dyteParticipantCount: {
        color: InQStyleGuide.white,
        margin: 0,
    },
}));
const config = Object.assign({}, defaultConfig);
if (config.root) {
    config.root['dyte-participant-tile'] = config.root['dyte-participant-tile'].children;
}
const CustomDyteMeeting = ({ onRoomLeft, onChatToggle, hasUnreadMessages, meetingName, }) => {
    const classes = useStyles();
    const { meeting } = useDyteMeeting();
    const roomJoined = useDyteSelector((m) => m.self.roomJoined);
    const [showParticipants, setShowParticipants] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [blurred, setBlurred] = useState(false);
    const [captioned, setCaptioned] = useState(false);
    const { clientComponent, setClientRef, isPortrait } = useDeviceOrientation();
    const videoBackgroundTransformerRef = useRef();
    const videoMiddlewareRef = useRef();
    const [frontCamera, setFrontCamera] = useState();
    const [backCamera, setBackCamera] = useState();
    const [currentCameraId, setCurrentCameraId] = useState();
    // Text and color customization
    const languagePack = useLocale();
    const customIconPack = useIconPack();
    // Perform actions when room is joined
    useEffect(() => {
        if (!meeting)
            return;
        const roomJoinedListener = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            raam.sttRoomConfig.addRoomParticipant(meetingName, meeting.self.customParticipantId);
            // Check if we have front/back cameras (taking first occurrence)
            const videoDevices = yield meeting.self.getVideoDevices();
            videoDevices.reverse().forEach((device) => {
                if (isFrontCamera(device)) {
                    setFrontCamera(device);
                }
                if (isBackCamera(device)) {
                    setBackCamera(device);
                }
            });
            setCurrentCameraId((_a = meeting.self.getCurrentDevices().video) === null || _a === void 0 ? void 0 : _a.deviceId);
        });
        meeting.self.on('roomJoined', roomJoinedListener);
        return () => {
            meeting.self.removeListener('roomJoined', roomJoinedListener);
        };
    }, [backCamera, frontCamera, meeting, meetingName]);
    // Setting up listener for when room is left
    useEffect(() => {
        if (!meeting)
            return;
        const roomLeftListener = () => {
            if (onRoomLeft) {
                raam.sttRoomConfig.removeRoomParticipant(meetingName, meeting.self.customParticipantId);
                onRoomLeft();
            }
        };
        meeting.self.on('roomLeft', roomLeftListener);
        return () => {
            meeting.self.removeListener('roomLeft', roomLeftListener);
        };
    }, [meeting, meetingName, onRoomLeft]);
    useEffect(() => {
        const handleResize = () => {
            setClientRef(clientComponent);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [setClientRef, clientComponent]);
    const onBlurToggle = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (!blurred) {
                if (!videoBackgroundTransformerRef.current) {
                    videoBackgroundTransformerRef.current =
                        yield DyteVideoBackgroundTransformer.init();
                    videoMiddlewareRef.current =
                        yield videoBackgroundTransformerRef.current.createBackgroundBlurVideoMiddleware(10);
                }
                if (videoMiddlewareRef.current) {
                    meeting.self.addVideoMiddleware(videoMiddlewareRef.current);
                    setBlurred(true);
                }
            }
            else {
                if (videoMiddlewareRef.current) {
                    meeting.self.removeVideoMiddleware(videoMiddlewareRef.current);
                    setBlurred(false);
                }
            }
        }
        catch (error) {
            console.error('Error occurred on blur', error);
        }
    });
    const onCaptionToggle = () => {
        raam.sttRoomConfig
            .setCaptioningState(meetingName, meeting.self.customParticipantId, !captioned)
            .then(setCaptioned(!captioned))
            .catch(() => console.error('Error while trying to set the captioningState in hive'));
    };
    const onCameraSwitch = () => {
        if (frontCamera && backCamera) {
            try {
                if (!currentCameraId || currentCameraId === backCamera.deviceId) {
                    meeting.self.setDevice(frontCamera);
                    setCurrentCameraId(frontCamera.deviceId);
                }
                else {
                    meeting.self.setDevice(backCamera);
                    setCurrentCameraId(backCamera.deviceId);
                }
            }
            catch (error) {
                console.error('Error occurred on camera toggle', error);
            }
        }
    };
    const onEndCall = () => {
        meeting.leave();
    };
    if (!roomJoined) {
        return (_jsxs(Box, Object.assign({ className: classes.meetingCallContainer }, { children: [_jsx(SetupScreen, { blurred: blurred, onBlurToggle: onBlurToggle }), _jsx(DyteDialogManager, { meeting: meeting, t: languagePack })] })));
    }
    const canSwitchCamera = frontCamera && backCamera;
    return (_jsxs("div", Object.assign({ ref: setClientRef, className: classes.meetingCallContainer }, { children: [_jsx(DyteParticipantsAudio, { meeting: meeting }), _jsx(DyteNotifications, { meeting: meeting, config: {
                    config: {
                        notifications: {
                            participant_joined: true,
                            participant_left: true,
                        },
                        notification_sounds: {
                            participant_joined: true,
                            participant_left: true,
                        },
                    },
                }, t: languagePack }), _jsxs(Box, Object.assign({ className: classes.controlsContainer }, { children: [_jsxs(Box, Object.assign({ className: clsx(classes.controlsRow, {
                            [classes.portrait]: isPortrait,
                        }) }, { children: [_jsx(DyteTooltip, Object.assign({ className: clsx(classes.controlItem, {
                                    [classes.portraitControlItem]: isPortrait,
                                }), label: languagePack('participants'), variant: "secondary", placement: "bottom", delay: TOOLTIP_DELAY }, { children: _jsx(DyteButton, Object.assign({ className: classes.customButton, size: "lg", variant: "secondary", onClick: () => setShowParticipants(!showParticipants) }, { children: _jsx(DyteParticipantCount, { className: classes.dyteParticipantCount, meeting: meeting }) })) })), !!onChatToggle && (_jsx(DyteTooltip, Object.assign({ className: clsx(classes.controlItem, {
                                    [classes.portraitControlItem]: isPortrait,
                                }), label: hasUnreadMessages
                                    ? languagePack('messaging_unread')
                                    : languagePack('messaging'), variant: "secondary", placement: "bottom", delay: TOOLTIP_DELAY, size: "sm" }, { children: _jsx(DyteControlbarButton, { className: classes.customControlButton, label: languagePack('messaging'), icon: hasUnreadMessages
                                        ? applyColor(chatWithDotSvgString, InQStyleGuide.green)
                                        : chatSvgString, onClick: onChatToggle, size: "sm" }) }))), _jsx(SettingsButton, { className: clsx(classes.controlItem, {
                                    [classes.portraitControlItem]: isPortrait,
                                }), onClick: () => setShowSettings(true) })] })), _jsxs(Box, Object.assign({ className: clsx({
                            [classes.bottomControlsContainer]: canSwitchCamera,
                        }) }, { children: [canSwitchCamera && (_jsx(DyteTooltip, Object.assign({ label: languagePack('switch_camera'), variant: "secondary", placement: "top", delay: TOOLTIP_DELAY }, { children: _jsx(DyteControlbarButton, { className: classes.customControlButton, label: languagePack('switch_camera'), icon: cameraSwitchSvgString, onClick: onCameraSwitch, size: "sm" }) }))), _jsxs(Box, Object.assign({ className: clsx(classes.controlsRow, {
                                    [classes.portrait]: isPortrait,
                                }) }, { children: [_jsx(DyteMicToggle, { className: clsx(classes.controlItem, {
                                            [classes.portraitControlItem]: isPortrait,
                                        }), size: "sm", meeting: meeting, t: languagePack, iconPack: customIconPack }), _jsx(DyteCameraToggle, { className: clsx(classes.controlItem, {
                                            [classes.portraitControlItem]: isPortrait,
                                        }), size: "sm", meeting: meeting, t: languagePack, iconPack: customIconPack }), _jsx(DyteTooltip, Object.assign({ className: clsx(classes.controlItem, {
                                            [classes.portraitControlItem]: isPortrait,
                                        }), label: blurred
                                            ? languagePack('disable_blur')
                                            : languagePack('enable_blur'), variant: "secondary", placement: "top", delay: TOOLTIP_DELAY }, { children: _jsx(DyteControlbarButton, { className: classes.customControlButton, icon: applyColor(blurSvgString, blurred ? InQStyleGuide.green : InQStyleGuide.white), onClick: onBlurToggle, size: "sm" }) })), _jsx(DyteTooltip, Object.assign({ className: clsx(classes.controlItem, {
                                            [classes.portraitControlItem]: isPortrait,
                                        }), label: languagePack('start_caption'), variant: "secondary", placement: "top", delay: TOOLTIP_DELAY }, { children: _jsx(DyteControlbarButton, { className: classes.customControlButton, label: languagePack('captions'), icon: applyColor(captionSvgString, captioned ? InQStyleGuide.green : InQStyleGuide.white), onClick: onCaptionToggle, size: "sm" }) })), _jsx(DyteScreenShareToggle, { className: clsx(classes.controlItem, {
                                            [classes.portraitControlItem]: isPortrait,
                                        }), size: "sm", meeting: meeting, t: languagePack, iconPack: customIconPack }), _jsx(DyteTooltip, Object.assign({ className: clsx(classes.controlItem, {
                                            [classes.portraitControlItem]: isPortrait,
                                        }), label: languagePack('disconnect'), variant: "secondary", placement: "top", delay: TOOLTIP_DELAY }, { children: _jsx(DyteControlbarButton, { className: classes.customControlButton, label: languagePack('disconnect'), icon: applyColor(endCallSvgString, InQStyleGuide.red), onClick: onEndCall, size: "sm" }) }))] }))] }))] })), _jsx(Box, Object.assign({ className: classes.videTilesContainer }, { children: _jsx(DyteGrid, { meeting: meeting, config: config, t: languagePack }) })), _jsx(DyteDialog, Object.assign({ open: showParticipants, onDyteDialogClose: () => setShowParticipants(false) }, { children: _jsx(DyteParticipants, { className: classes.dyteParticipants, meeting: meeting, t: languagePack, iconPack: customIconPack }) })), _jsx(SettingsDialog, { open: showSettings, onDialogClose: () => setShowSettings(false), blurred: blurred, onBlurToggle: onBlurToggle }), _jsx(DyteDialogManager, { meeting: meeting, t: languagePack, iconPack: customIconPack })] })));
};
export const MeetingCall = (_a) => {
    var { meeting } = _a, props = __rest(_a, ["meeting"]);
    return (_jsx(DeviceOrientationProvider, { children: _jsx(DyteProvider, Object.assign({ value: meeting }, { children: _jsx(CustomDyteMeeting, Object.assign({}, props)) })) }));
};
