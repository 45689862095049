import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import i18next from 'i18next';
import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash';
import { SinglePanelLayout } from '../../Components';
import { useExtendedTranslation } from '../../Services/i18nService';
import { AppStates } from '../../Types/IAppState';
import useApplicationConfig from '../../Hooks/useApplicationConfig';
import useClinicConfig from '../../Hooks/useClinicConfig';
import ScreenUnsupportedBrowser from '../Errors/ScreenUnsupportedBrowser';
import { Video } from 'raam-shared';

const AppStatesToMessages = {
  OPEN: 'msgOpen',
  BREAK: 'msgBreak',
  FORCECLOSE: 'msgForcedClose',
  CLOSED: 'msgClosed',
};

function FrontPage({
  onNext,
  appState,
  onAbout,
}: {
  appState: AppStates;
  onNext: Function;
  onAbout: Function;
}) {
  const theme = useTheme();
  const applicationConfig = useApplicationConfig();
  const messages = _.get(applicationConfig, AppStatesToMessages[appState]);
  const clinicConfig = useClinicConfig();
  const [footer, setFooter] = useState<JSX.Element | undefined>(undefined);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const t = useExtendedTranslation();

  useEffect(() => {
    if (Video.isSupported) {
      if (appState === AppStates.OPEN) {
        setFooter(
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={
                  hasSubmitted && <CircularProgress color="inherit" size={15} />
                }
                disabled={hasSubmitted}
                onClick={() => {
                  setHasSubmitted(true);
                  onNext();
                }}
              >
                {t('welcome.checkin.button')}
              </Button>
            </Grid>
            <Box width="100%" height={15} />
            <Grid item>
              <Button
                size="small"
                style={{
                  padding: 0,
                  backgroundColor: 'transparent',
                }}
                onClick={() => onAbout()}
              >
                <Typography
                  color="secondary"
                  variant="body2"
                  style={{
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                    fontStyle: 'italic',
                  }}
                >
                  {t('about.title')}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        );
      } else {
        setFooter(undefined);
      }
    } else {
      setFooter(<ScreenUnsupportedBrowser />);
    }
  }, [t, appState, hasSubmitted, onNext, onAbout]);

  return (
    <Box display="flex" flex={1}>
      <SinglePanelLayout footer={footer} logo={clinicConfig.logoName}>
        <>
          <span style={{ color: theme.palette.primary.main }}>
            {ReactHtmlParser(messages.primary[i18next.language as 'en' | 'fr'])}
          </span>
          <>
            {messages.displayCheckInHours && (
              <>
                <Typography
                  variant="body2"
                  color="primary"
                  align="center"
                  style={{ fontWeight: 'bold' }}
                >
                  {t('welcome.checkin.hours.title')}
                </Typography>
                <div
                  style={{
                    textAlign: 'center',
                    color: theme.palette.primary.main,
                  }}
                >
                  {ReactHtmlParser(
                    applicationConfig.msgCheckinHours[
                      i18next.language as 'en' | 'fr'
                    ]
                  )}
                </div>
              </>
            )}
          </>
          <span style={{ color: theme.palette.primary.main }}>
            {ReactHtmlParser(
              messages.secondary[i18next.language as 'en' | 'fr']
            )}
          </span>
        </>
      </SinglePanelLayout>
    </Box>
  );
}

export default FrontPage;
