import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { DyteDialog, DyteSettingsAudio, DyteSettingsVideo, DyteSwitch, } from '@dytesdk/react-ui-kit';
import { useDyteMeeting } from '@dytesdk/react-web-core';
import _ from 'lodash';
import InQStyleGuide from '../../constants';
import useLocale from '../../hooks/useLocale';
const useStyles = makeStyles((theme) => ({
    dialog: {
        position: 'absolute',
        overflow: 'hidden',
    },
    dialogContent: {
        borderRadius: theme.spacing(1),
        backgroundColor: InQStyleGuide.black,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        maxHeight: '100%',
        width: theme.spacing(70),
    },
    dialogHeader: {
        padding: `0 ${theme.spacing(3)}px`,
    },
    dialogBody: {
        flex: 1,
        marginBottom: theme.spacing(3),
        overflow: 'auto',
        padding: `0 ${theme.spacing(3)}px`,
    },
    select: {
        backgroundColor: InQStyleGuide.grey.medium,
        borderRadius: theme.spacing(0.5),
        border: 'none',
        color: InQStyleGuide.white,
        fontSize: '16px',
        padding: theme.spacing(1.5),
        width: '100%',
    },
}));
export default function SettingsDialog({ open, onDialogClose, blurred, onBlurToggle, }) {
    const classes = useStyles();
    const { meeting } = useDyteMeeting();
    const languagePack = useLocale();
    const [languageCode, setLanguageCode] = useState('en-CA'); // TODO use default/saved value
    const primaryCaptionLanguages = languagePack('PrimaryCaptionLanguages');
    const otherCaptionLanguages = languagePack('OtherCaptionLanguages');
    const primaryLanguages = useMemo(() => _.map(primaryCaptionLanguages, (entry) => {
        return (_jsx("option", Object.assign({ value: entry.locale }, { children: entry.languageName }), entry.locale));
    }), [primaryCaptionLanguages]);
    const otherLanguages = useMemo(() => _.map(otherCaptionLanguages, (entry) => {
        return (_jsx("option", Object.assign({ value: entry.locale }, { children: entry.languageName }), entry.locale));
    }), [otherCaptionLanguages]);
    const onChangeLanguage = (e) => {
        const nextLanguageCode = _.get(e, 'target.value', '');
        setLanguageCode(nextLanguageCode);
        // TODO apply/save value
    };
    return (_jsx(DyteDialog, Object.assign({ className: classes.dialog, open: open, onDyteDialogClose: onDialogClose }, { children: _jsxs(Box, Object.assign({ className: classes.dialogContent }, { children: [_jsxs(Box, Object.assign({ className: classes.dialogHeader }, { children: [_jsx("h2", { children: languagePack('settings') }), _jsx("hr", {})] })), _jsxs(Box, Object.assign({ className: classes.dialogBody }, { children: [_jsx("h3", { children: languagePack('video') }), _jsx(DyteSettingsVideo, { meeting: meeting, t: languagePack }), _jsxs(Box, Object.assign({ style: { display: 'flex', justifyContent: 'space-between' } }, { children: [_jsx("span", { children: languagePack('blur_toggle') }), _jsx(DyteSwitch, { style: { boxSizing: 'border-box' }, checked: blurred, onDyteChange: onBlurToggle })] })), _jsx("hr", {}), _jsx("h3", { children: languagePack('audio') }), _jsx(DyteSettingsAudio, { meeting: meeting, t: languagePack }), _jsx("hr", {}), _jsx("h3", { children: languagePack('captions') }), _jsx("span", { children: languagePack('captions_select') }), _jsx(Box, Object.assign({ style: { display: 'flex' } }, { children: _jsxs("select", Object.assign({ className: classes.select, value: languageCode, onChange: onChangeLanguage }, { children: [primaryLanguages, _jsx("hr", {}), otherLanguages] })) }))] }))] })) })));
}
