import React, { useCallback, useEffect, useRef } from 'react';
import { Box } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { raam } from 'raam-client-lib';
import { MeetingCall, useMeetingClient } from 'raam-shared';
import { joinAsGuest } from '../../Services/LinkService';
import Connecting from '../Meeting/Connecting';

function LinkMeeting({
  meetingName,
  firstName,
  lastName,
  onLeaving,
  meetingPin,
}: {
  meetingName: string;
  firstName: string;
  lastName: string;
  meetingPin: string;
  onLeaving: (...a: any) => void;
}) {
  const [meeting, initMeeting] = useMeetingClient({ resetOnLeave: true }); // Deletes 'meeting' object on leave
  const hasReceivedToken = useRef(false);
  const userId = uuidv4(); // random userId, as it's a one-time (random) guest

  useEffect(() => {
    joinAsGuest(meetingName, `${firstName} ${lastName}`);
  }, [firstName, lastName, meetingName]);

  const initDyteCall = useCallback(() => {
    hasReceivedToken.current = true;
    raam.patron
      .getRoomToken(`${firstName} ${lastName}`, userId, meetingName, meetingPin)
      .then((res: any) => {
        initMeeting({
          authToken: res.token,
        }).catch(() => {
          hasReceivedToken.current = false;
        });
      })
      .catch(() => {
        onLeaving(false);
      });
  }, [firstName, initMeeting, lastName, meetingName, meetingPin, onLeaving]);

  useEffect(() => {
    if (!hasReceivedToken.current) {
      initDyteCall();
    }
  }, [initDyteCall]);

  return (
    <Box className="meeting-panel">
      {meeting ? (
        <MeetingCall
          meeting={meeting}
          onRoomLeft={() => onLeaving(true)}
          meetingName={meetingName}
        />
      ) : (
        <Connecting />
      )}
    </Box>
  );
}

export default LinkMeeting;
