import { Box, Button, Card, SvgIcon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { SnackbarContent, useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { raam } from 'raam-client-lib';
import { MeetingCall, useMeetingClient } from 'raam-shared';
import MessagingDialog from '../../Components/Layout/SinglePanel/MessagingDialog/MessagingDialog';
import { ReactComponent as Unread } from '../../Components/Layout/SinglePanel/icons/chat_message_waiting.svg';
import { useChatContext } from '../../Contexts/ChatContext';
import { useMaybePromise, useObservable, usePrevious } from '../../Hooks';
import { ChatView, ParticipantType, createChatView } from '../../Services/Chat';
import { useExtendedTranslation } from '../../Services/i18nService';
import IPatron from '../../Types/IPatron';
import { SinglePanelWithMessaging } from '../../Components';
import Connecting from './Connecting';
import Rejoin from './Rejoin';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important',
    },
  },
  card: {
    backgroundColor: 'white',
    width: '100%',
    textAlign: 'left',
    padding: '0.5rem 0.5rem 0 0.5rem',
  },
  messageIcon: {
    verticalAlign: 'middle',
  },
  messageText: {
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: '0.5rem',
  },
  tapButton: {
    color: '#027AC5',
  },
}));

const messageHasInitial = (s: string): boolean => _.get(s, 2) === '|';
const removeMaybeInitial = (s: string): string =>
  messageHasInitial(s) ? _.join(_.slice(s, 3), '') : s;

enum MeetingState {
  InMeeting,
  InRejoin,
}

function Meeting({
  patron,
  onContinue,
}: {
  patron: IPatron;
  onContinue: Function;
}) {
  useExtendedTranslation();
  const [currentState, setCurrentState] = useState(
    patron.properties.meetingName
      ? MeetingState.InMeeting
      : MeetingState.InRejoin
  );
  const { showMessaging, updateShowMessaging } = useChatContext();
  const [chatPromise, setChatPromise] = useState<Promise<ChatView>>();
  const chatUnwrapped = useMaybePromise(chatPromise);
  const hasUnreadMessages = useObservable(
    false,
    chatUnwrapped?.hasUnreadMessages
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const messagesList = useObservable([], chatUnwrapped?.messages);
  const previous = usePrevious(messagesList);
  const classes = useStyles();
  const t = useExtendedTranslation();
  const [meeting, initMeeting] = useMeetingClient({ resetOnLeave: true }); // Deletes 'meeting' object on leave
  const authToken = useRef('');

  const initDyteCall = useCallback(() => {
    authToken.current = 'AwaitingToken';
    raam.patron
      .getRoomToken(
        `${patron.properties.firstName} ${patron.properties.lastName}`,
        patron.id,
        patron.properties.meetingName,
        patron.properties.meetingPin || ''
      )
      .then((res: any) => {
        initMeeting({
          authToken: res.token,
        })
          .then(() => {
            authToken.current = res.token;
          })
          .catch(() => {
            authToken.current = '';
          });
      });
  }, [
    initMeeting,
    patron.id,
    patron.properties.firstName,
    patron.properties.lastName,
    patron.properties.meetingName,
    patron.properties.meetingPin,
  ]);

  useEffect(() => {
    if (!authToken.current) {
      initDyteCall();
    }
  }, [initDyteCall]);

  const onReconnect = () => {
    if (patron.properties.meetingName) {
      initMeeting({ authToken: authToken.current });
      initDyteCall();
      setCurrentState(MeetingState.InMeeting);
    } else {
      window.open(patron.properties.meetingUrl);
    }
  };

  // For creating Chat message notification content
  const getContent = useCallback(
    (messageToDisplay: string) => {
      return (
        <SnackbarContent className={classes.root}>
          <Card className={classes.card}>
            <Typography variant="body1">
              <SvgIcon component={Unread} className={classes.messageIcon} />
              {`  ${t('messagenotif.newmessage')}`}
            </Typography>
            <Typography variant="body1" className={classes.messageText}>
              &quot;{removeMaybeInitial(messageToDisplay)}&quot;
            </Typography>
            <Box textAlign="center">
              <Button
                onClick={() => updateShowMessaging(true)}
                variant="text"
                className={classes.tapButton}
              >
                {t('messagenotif.respond')}
              </Button>
            </Box>
          </Card>
        </SnackbarContent>
      );
    },
    [
      classes.card,
      classes.messageIcon,
      classes.messageText,
      classes.root,
      classes.tapButton,
      t,
      updateShowMessaging,
    ]
  );

  // Setting up chat view (for chat dialog)
  useEffect(() => {
    const conversationId = patron?.properties?.conversationId;
    const patronId = patron?.id;
    if (conversationId && patronId) {
      const chat = createChatView(
        conversationId,
        ParticipantType.User,
        patronId
      );
      setChatPromise(chat);
    }
  }, [patron]);

  useEffect(() => {
    setCurrentState(
      patron.properties.meetingName
        ? MeetingState.InMeeting
        : MeetingState.InRejoin
    );
  }, [patron.properties.meetingName]);

  // Showing Chat message notification
  useEffect(() => {
    if (!showMessaging && hasUnreadMessages) {
      if (_.size(previous) < _.size(messagesList)) {
        const messageToDisplay = _.get(_.last(messagesList), 'message', '');
        enqueueSnackbar('', {
          key: messageToDisplay,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          content: getContent(messageToDisplay),
          preventDuplicate: true,
          persist: false,
        });
      }
    } else if (showMessaging) {
      closeSnackbar();
    }
  }, [
    previous,
    hasUnreadMessages,
    showMessaging,
    messagesList,
    enqueueSnackbar,
    closeSnackbar,
    getContent,
  ]);

  return (
    <Box className="meeting-panel">
      {currentState === MeetingState.InMeeting ? (
        meeting ? (
          <>
            <MeetingCall
              meeting={meeting}
              onRoomLeft={() => setCurrentState(MeetingState.InRejoin)}
              onChatToggle={() => updateShowMessaging(true)}
              hasUnreadMessages={hasUnreadMessages}
              meetingName={patron.properties.meetingName}
            />
            <MessagingDialog chat={chatPromise} patron={patron} />
          </>
        ) : (
          <Connecting />
        )
      ) : (
        <SinglePanelWithMessaging patron={patron}>
          <Rejoin onContinue={onContinue} onRejoin={onReconnect} />
        </SinglePanelWithMessaging>
      )}
    </Box>
  );
}

export default Meeting;
