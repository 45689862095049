import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from '@material-ui/core';
import _ from 'lodash';
import { createContext, useCallback, useContext, useMemo, useState, } from 'react';
export const DeviceOrientationContext = createContext(null);
export const DeviceOrientationProvider = ({ children }) => {
    const theme = useTheme();
    const [clientDimension, setClientDimension] = useState({
        width: 0,
        height: 0,
    });
    const [clientComponent, setClientComponent] = useState();
    const setClientRef = useCallback((newClientComponent) => {
        setClientComponent(newClientComponent);
        setClientDimension({
            width: _.get(newClientComponent, 'clientWidth', 0),
            height: _.get(newClientComponent, 'clientHeight', 0),
        });
    }, []);
    const isPortrait = useMemo(() => clientDimension.width < clientDimension.height &&
        clientDimension.width < theme.breakpoints.values.sm, [clientDimension, theme.breakpoints.values.sm]);
    return (_jsx(DeviceOrientationContext.Provider, Object.assign({ value: { setClientRef, clientDimension, clientComponent, isPortrait } }, { children: children })));
};
export function useDeviceOrientation() {
    const context = useContext(DeviceOrientationContext);
    if (!context) {
        throw new Error('useResponsive must be used within the DeviceOrientationProvider');
    }
    return context;
}
