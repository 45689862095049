var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { DyteAudioVisualizer, DyteAvatar, DyteButton, DyteCameraToggle, DyteMicToggle, DyteNameTag, DyteParticipantTile, } from '@dytesdk/react-ui-kit';
import { useDyteMeeting } from '@dytesdk/react-web-core';
import { Box, Typography, makeStyles } from '@material-ui/core';
import SettingsButton from './Settings/SettingsButton';
import SettingsDialog from './Settings/SettingsDialog';
import useIconPack from '../hooks/useIconPack';
import useLocale from '../hooks/useLocale';
const useStyles = makeStyles((theme) => ({
    setupContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: 'auto',
        padding: theme.spacing(3),
    },
    controlsContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        justifyContent: 'space-between',
    },
    controlsRow: {
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    controlItem: {
        marginRight: theme.spacing(1),
    },
    text: {
        marginTop: theme.spacing(3),
        textAlign: 'center',
    },
    name: {
        fontSize: '28px',
        marginBottom: theme.spacing(3),
        textAlign: 'center',
    },
}));
export default function SetupScreen({ blurred, onBlurToggle }) {
    const classes = useStyles();
    const { meeting } = useDyteMeeting();
    const languagePack = useLocale();
    const customIconPack = useIconPack();
    const [showSettings, setShowSettings] = useState(false);
    const onJoin = () => __awaiter(this, void 0, void 0, function* () {
        yield meeting.join();
    });
    return (_jsxs(Box, Object.assign({ className: classes.setupContainer }, { children: [_jsx(Box, { children: _jsxs(DyteParticipantTile, Object.assign({ meeting: meeting, participant: meeting.self }, { children: [_jsx(Box, { children: _jsx(DyteAvatar, { participant: meeting.self }) }), _jsx(DyteNameTag, Object.assign({ participant: meeting.self, t: languagePack }, { children: _jsx(DyteAudioVisualizer, { participant: meeting.self, slot: "start" }) })), _jsxs(Box, Object.assign({ className: classes.controlsContainer }, { children: [_jsx(Box, Object.assign({ className: classes.controlsRow }, { children: _jsx(SettingsButton, { onClick: () => setShowSettings(true) }) })), _jsxs(Box, Object.assign({ className: classes.controlsRow }, { children: [_jsx(DyteMicToggle, { className: classes.controlItem, meeting: meeting, size: "sm", t: languagePack, iconPack: customIconPack }), _jsx(DyteCameraToggle, { meeting: meeting, size: "sm", t: languagePack, iconPack: customIconPack })] }))] }))] })) }), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ className: classes.text }, { children: languagePack('setup_screen.joining_as') })), _jsx(Typography, Object.assign({ className: classes.name }, { children: meeting.self.name })), _jsx(DyteButton, Object.assign({ kind: "wide", size: "lg", onClick: onJoin }, { children: languagePack('join') }))] }), _jsx(SettingsDialog, { open: showSettings, onDialogClose: () => setShowSettings(false), blurred: blurred, onBlurToggle: onBlurToggle })] })));
}
